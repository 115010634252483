class RentalApplicationSignature {
  initTemplateEditor(field) {
    if (!$('#' + field.id).length) { return; }
    this.addRichTemplateEditor(field);
  }

  addRichTemplateEditor(field) {
    var settings = {
      toolbar: ['Bold', 'Italic', 'Insert'],
      unusedMenuItems: ['Insert Horizontal Line'],
      editorHeight: 130,
      enable: true
    };
    return new TemplateRichEditor('#rental_application_signature_form', field.id, settings);
  }

  init(field) {
    this.initTemplateEditor(field);
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const field = document.querySelector('#js-ra-signature-field');
  if (field) {
    new RentalApplicationSignature().init(field);
  }
});
