import LayoutUtils from '../../../utils/layout_utils';
import RequiredListingUpdatesUtils from './utils';

class ShowingNotificationContactsUpdates {
  bindShowingNotificationContactAdd(wrapper) {
    const btns = document.querySelectorAll(`${wrapper} .js-listing-contacts .contact-create-button`);
    btns.forEach((btn) => {
      btn.addEventListener('click', () => {
        const skipSnooze = btn.closest('.skippable').querySelectorAll('.skip input[type=button], .snooze input[type=button]');
        const contactsWrapper = btn.closest('.js-listing-contacts');
        $(document).ajaxStop(() => {
          if (contactsWrapper.querySelector('.contact-show')) {
            LayoutUtils.disableButton(skipSnooze);
            LayoutUtils.hide(skipSnooze);
          }
        });
      });
    });
    
  }

  addDeleteContactHandler(elem) {
    const btn = elem.closest('.skippable').querySelectorAll('.skip input[type=button], .snooze input[type=button]');
    const fieldsWrapper = elem.closest('.field-wrapper');
    const contactsWrapper = elem.closest('.js-listing-contacts');
    $(document).ajaxStop(() => {
      if (!contactsWrapper.querySelector('.contact-show')) {
        if (!RequiredListingUpdatesUtils.anyNonHiddenInputsFilled(fieldsWrapper)) {
          LayoutUtils.enableButton(btn);
        }
        LayoutUtils.show(btn);
      }
    });
  }

  bindShowingNotificationContactDelete(wrapper) {
    const btns = document.querySelectorAll(`${wrapper} .js-listing-contacts .contact-delete-button`);
    btns.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        this.addDeleteContactHandler(e.target);
      });
    });
  }

  bindEvents(wrapper) {
    this.bindShowingNotificationContactAdd(wrapper);
    this.bindShowingNotificationContactDelete(wrapper);
    document.addEventListener('lisiting-updates:rebind-input-events', (e) => {
      if (e.detail.container.nodeType) {
        const deleteBtn = e.detail.container.querySelector('.contact-delete-button');
        
        if (deleteBtn) {
          this.addDeleteContactHandler(deleteBtn);
        }
      }
    });
  }

  init(wrapper) {
    document.querySelectorAll(`${wrapper} .js-listing-contacts`).forEach((lc) => {
      var listingId = lc.getAttribute('data-listing-id');
      var form = new ListingContactsForm(listingId); // eslint-disable-line
      form.init();
    });
    this.bindEvents(wrapper);
  }
}

export default ShowingNotificationContactsUpdates;