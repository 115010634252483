class MultiUserListingsAndShowingsForm {
  constructor(wrapper) {
    this.formSelector = '#' + wrapper.id + ' form';
    this.wrapper = wrapper;
    this.templateEditorId = 'account_showing_intake_same_agent_message_template';
    this.templateEditor = null;
  }

  setTemplateEditorToEditMode() {
    if (!this.templateEditor) { return; }
    this.templateEditor.setEditMode();
  }

  setTemplateEditorToShowMode() {
    if (!this.templateEditor) { return; }
    this.templateEditor.setShowMode();
  }

  resetTemplateEditor() {
    if (!this.templateEditor) { return; }
    this.templateEditor.resetContent();
  }

  bindEvents() {
    this.form.addEventListener('submitted-with-errors', () => {
      document.querySelector(this.form).dispatchEvent(new CustomEvent('partial-form:enable'));
    });

    this.form.addEventListener('cancelled', () => {
      this.resetTemplateEditor();
      this.setTemplateEditorToShowMode();
    });

    this.form.addEventListener('enabled', () => {
      this.setTemplateEditorToEditMode();
    });
  }

  initTemplateEditor() {
    let field = this.form.querySelector('#' + this.templateEditorId);
    if (field !== null) {
      this.templateEditor = this.addRichTemplateEditor(this.templateEditorId);
    }
  }

  addRichTemplateEditor(fieldId) {
    var settings = { toolbar: ['InsertPlaceholder', 'RestoreDefaultTemplate'], editorHeight: 200 };
    return new TemplateRichEditor(this.formSelector, fieldId, settings);
  }

  initForm() {
    this.form = this.wrapper.querySelector('form');

    this.initTemplateEditor();

    this.bindEvents();
  }

  init() {
    this.initForm();

    this.wrapper.addEventListener('html-updated', (e) => {
      CKEDITOR.instances.account_showing_intake_same_agent_message_template.removeAllListeners();
      CKEDITOR.remove(CKEDITOR.instances.account_showing_intake_same_agent_message_template);

      this.initForm();

      if (e.detail && JSON.parse(e.detail.errors)) {
        CKEDITOR.instances.account_showing_intake_same_agent_message_template.on('instanceReady', () => {
          this.wrapper.querySelector('form').dispatchEvent(new CustomEvent('submitted-with-errors'));
        });
      }
    });
  }
}

document.addEventListener('MultiUserListingsAndShowingsFormLoaded', function(){
  const wrapper = document.getElementById('multi_user_listings_and_showings');
  if (wrapper) {
    var settingsInstance = new MultiUserListingsAndShowingsForm(wrapper);
    settingsInstance.init();
  }
});
document.addEventListener('DOMContentLoaded', function() {
  document.dispatchEvent(new CustomEvent('MultiUserListingsAndShowingsFormLoaded'));
});
