import DeviceConstructor from './device_constructor';
import DeviceUtils from './device_utils';
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';

class OrderDevice {
  #device; // It's updated when user clicks on Order button for specific device
  #account; // Account info from server side
  #types; // Available devices types
  #scaledPricing; // Additional info for price calculation
  #wrapper; // Dom container for modal window with shipping form and confirm button OR wrapper for form on Account Setup page
  #sameKeyAdditionalFee; // Additional info for price calculation
  #selectedDeviceField; // Field to store selected device when shipping modal window is opened
  #devicesCarousels = []; // Variable to store all instances of devices carousels

  getDevicePrice(device, lockboxType, value) {
    let price = 0;
    
    if (this.#account.codebox_scaled_pricing) {
      price = this.#scaledPricing.filter(function(x) {
        return x.from <= value && value <= x.to;
      })[0].price[lockboxType];
    } else {
      price = device.getPrice();
    }
    return price;
  }

  // Calculates price for selected device, update html in shipping modal for single device price and total price for selected devices amount
  setTotalAmountText(totalAmountPrefix = '') {
    var val = this.#wrapper.querySelector('.js-devices-count-field').value;
    var lockbox_type = this.getSelectedDeviceType();
    let price;
    const priceWrapper = this.#wrapper.querySelector('.codebox.count strong');
    if (val >= 0 && lockbox_type) {
      price = this.getDevicePrice(this.#device, lockbox_type, val);
      this.#device.printFormattedPrice();

      var same_key_enabled = this.#wrapper.querySelector('input.same_key_enabled').checked;
      var sameKeyFee = (lockbox_type == this.#types.TYPE_VAULT && same_key_enabled) ? this.#sameKeyAdditionalFee : 0;
      var amount = val * (price + sameKeyFee);
      price = price || 0;
      amount = amount || 0;
      if (priceWrapper) {
        priceWrapper.innerHTML = '$' + price;
      }
      this.#wrapper.querySelector('#total_amount_text').innerHTML = `${totalAmountPrefix}$${amount}`;
    } else {
      if (priceWrapper) {
        priceWrapper.innerHTML = '$0';
      }
      this.#wrapper.querySelector('#total_amount_text').innerHTML = '$0';
    }
  }

  // Calculates min and max amount for selected device
  changeMinMaxQuantity() {
    var quantityInput = this.#wrapper.querySelector('.js-devices-count-field');
    var max;

    const minItemsDefault = Number(quantityInput.min);
    const maxItemsDefault = Number(quantityInput.max);

    if (min == undefined) {
      var min = minItemsDefault;
    }
    if (max == undefined) {
      max = maxItemsDefault;
    }
    if (min > max) {
      min = max;
    }

    if (Number(quantityInput.value) < Number(min)) {
      quantityInput.value = min;
    } else if (Number(quantityInput.value) > Number(max)) {
      quantityInput.value = max;
    }

    quantityInput.min = min;
    quantityInput.max = max;
  }

  showLoader() {
    document.querySelector('.js-modal-loader').classList.remove('hidden');
    this.#wrapper.querySelector('#new_lockbox_order_form').classList.add('disabled');
  }

  hideLoader() {
    document.querySelector('.js-modal-loader').classList.add('hidden');
    const form = this.#wrapper.querySelector('#new_lockbox_order_form');
    if (form) {
      form.classList.remove('disabled');
    }
  }

  initModal() {
    this.bindModalEvents();

    this.toggleCodeboxNote();
    this.toggleAddressBehavior();
    this.setTotalAmountText();
    this.changeMinMaxQuantity();

    this.toggleKeyNumber();
    this.toggletableLockboxSameKey();

    // Update selected device name for shipping modal window 
    document.querySelector(`#${this.#wrapper.id} .js-modal-content .js-selected-device-name`).innerHTML 
      = this.#selectedDeviceField.closest('.js-device-info').querySelector('.js-device-name').innerText;

    this.hideLoader();
  }

  toggletableLockboxSameKey() {
    var codeboxType = this.getSelectedDeviceType();
    var tableLockboxSameKey = $(this.#wrapper.querySelector('table.lockbox_same_key'));

    if (codeboxType == this.#types.TYPE_VAULT) {
      tableLockboxSameKey.show();
    } else {
      tableLockboxSameKey.hide();
    }
  }

  toggleKeyNumber() {
    var checkedSameKeyEnabled = this.#wrapper.querySelector('input.same_key_enabled').checked;
    var pLockboxSameKeyNumber = $(this.#wrapper.querySelector('.lockbox-same-key-number'));
    if (checkedSameKeyEnabled) {
      pLockboxSameKeyNumber.show();
    } else {
      pLockboxSameKeyNumber.hide();
    }
  }

  // Events for form on the Account Setup page
  bindAccountSetupEvents() {
    const accountSetupOrderForm = document.querySelector('.js-account-setup-order-form');
    if (accountSetupOrderForm) {
      const amountPrefix = 'Your credit card will be charged ';
      this.#wrapper = accountSetupOrderForm;
      this.#device = new DeviceConstructor(this.getSelectedDeviceType(), this.#types, this.#account);
      accountSetupOrderForm.querySelector('#lockbox_order_form_lockbox_type').addEventListener('change', () => {
        this.#device = new DeviceConstructor(this.getSelectedDeviceType(), this.#types, this.#account);
        this.setTotalAmountText(amountPrefix);
        this.toggleAddressBehavior();
        this.toggleKeyNumber();
        this.toggleCodeboxNote();
        this.toggletableLockboxSameKey();
      });

      this.toggleKeyNumber();
      this.toggleCodeboxNote();
      this.toggletableLockboxSameKey();
      this.setTotalAmountText(amountPrefix);

      accountSetupOrderForm.querySelector('#lockbox_order_form_item_count').addEventListener('change', () => {
        this.setTotalAmountText(amountPrefix);
      });
    }
  }

  bindEvents() {
    const btns = document.querySelectorAll('.js-order-device-btn');
    btns.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        this.#selectedDeviceField = btn.closest('.js-device-info').querySelector('.js-selected-device');
        const selectedDevice = this.#selectedDeviceField.value;
        const modalId = `${selectedDevice}-sm-modal`;
        document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {content: 
          document.querySelector('.js-order-modal-content').innerHTML,
          modalId
        }}));
        this.#wrapper = document.querySelector(`#${modalId}`);
        this.#wrapper.querySelector('.js-selected-device').value = selectedDevice;
        this.#wrapper.querySelector('.js-order-device-shipping-time').innerHTML = btn.closest('.js-device-info').querySelector('.js-device-shipping-time').innerText;
        this.#device = new DeviceConstructor(this.getSelectedDeviceType(), this.#types, this.#account);
        this.initModal();
        this.deviceChangeHandler(selectedDevice);
        return false;
      });
    });

    document.addEventListener('html-updated', () => {
      this.initModal();
    });
    
    window.addEventListener('resize', () => {
      DeviceUtils.setDescriptionLinesHeigth();
    });

    document.addEventListener('sidebar:state-changed', () => {
      DeviceUtils.setDescriptionLinesHeigth();
      this.#devicesCarousels.forEach(splide => {
        splide.refresh();
      });
    });

    this.bindAccountSetupEvents();
    this.initTabs();
  }

  initTabs() {
    document.querySelectorAll('.js-devices-group').forEach(el => {
      el.addEventListener('click', () => {
        document.querySelectorAll('.js-devices-group').forEach(g => {
          g.classList.toggle('current');
        });

        document.querySelectorAll('.js-devices').forEach(d => {
          d.classList.toggle('hidden');
        });

        DeviceUtils.setDescriptionLinesHeigth();
      });
    });
  }

  toggleCodeboxNote() {
    this.#device.toggleCodeboxNote();
  }

  toggleAddressBehavior() {
    const isCanadianLockboxOrderAllowed = JSON.parse(document.querySelector('#codebox-order').dataset.isCanadianLockboxOrderAllowed);
    if (!isCanadianLockboxOrderAllowed) {
      this.#device.toggleAddressBehavior();
    }
  }

  getSelectedDeviceType() {
    return this.#wrapper.querySelector('.js-selected-device').value;
  }

  deviceChangeHandler(device) {
    this.#device = new DeviceConstructor(device, this.#types, this.#account);
    this.toggleCodeboxNote();
    this.toggleAddressBehavior();

    this.toggletableLockboxSameKey();
  }

  bindModalEvents() {
    this.#wrapper.querySelector('.js-submit-order').addEventListener('click', (e) => {
      e.preventDefault();
      const form = this.#wrapper.querySelector('#new_lockbox_order_form');
      this.showLoader();
      $.ajax({
        method: 'put',
        url: form.action,
        dataType: 'script',
        data: $(form).serializeArray()
      });
      return false;
    });

    $('input.same_key_enabled').on('change', () => {
      this.toggleKeyNumber();
      this.setTotalAmountText();
    });

    $('.js-sm-modal .js-devices-count-field').on('change', () => {
      this.toggleCodeboxNote();
      this.toggleAddressBehavior();
      this.setTotalAmountText();
    });

    document.addEventListener('hide-loader', () => {
      this.hideLoader();
    });
  }

  renderPrices() {
    document.querySelectorAll('.js-device-price').forEach(p => {
      const infoWrapper = p.closest('.js-device-info');
      const deviceCode = infoWrapper.querySelector('.js-selected-device').value;
      const price = this.getDevicePrice(new DeviceConstructor(deviceCode, this.#types, this.#account), deviceCode, 1);
      infoWrapper.querySelector('.js-device-price').innerHTML = `$${price}`;
    });
  }

  initDevicesCarousels() {
    document.querySelectorAll('.js-devices-carousels').forEach(el => {
      const columns = el.querySelectorAll('.js-device-info');
      let breakpoints = {
        900: {
          perPage: 2,
        },
        710: {
          perPage: 1,
      }};
      let perPage = 3;
      if (columns.length < 3) {
        breakpoints = {
          900: {
            perPage: columns.length,
          },
          710: {
            perPage: 1,
          }
        };
        perPage = columns.length;
      }
      
      const splide = new Splide(el, {
        pagination: false, 
        perPage,
        breakpoints
      }).mount();

      this.#devicesCarousels.push(splide);
    });
  }

  init(orderPage) {
    this.#account = JSON.parse(orderPage.dataset.account);
    this.#types = JSON.parse(orderPage.dataset.types);
    this.#sameKeyAdditionalFee = JSON.parse(orderPage.dataset.sameKeyAdditionalFee);
    this.#scaledPricing = JSON.parse(orderPage.dataset.scaledPricing);

    this.bindEvents();
    this.renderPrices();
    this.initDevicesCarousels();

    DeviceUtils.setDescriptionLinesHeigth();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const orderPage = document.querySelector('#codebox-order');
  const od = new OrderDevice();
  if (orderPage) {
    od.init(orderPage);
  }
});