class OptionsForm {
  bindEvents(form) {
    document.querySelector('#edit-listing-options').addEventListener('click', () => {
      document.querySelector(form).dispatchEvent(new CustomEvent('partial-form:enable'));
    });

    $('#listing_application_link_policy').on('change', function() {
      if ($(this).val() == 'APPLICATION_LINK_POLICY_CUSTOM_LINK') {
        $('#custom_application_url').show('fast');
      } else {
        $('#custom_application_url').hide();
      }
    });

    $('#listing_block_scheduled_showings').on('change', function() {
      const blockShowingsValue = $(this).val();
      if (blockShowingsValue == 'BEFORE') {
        $('span#block_scheduled_showings_before').show();
        $('#listing_block_until, #listing_block_until_time').removeAttr('disabled');

        $('span#block_scheduled_showings_after').hide();
        $('#listing_block_from, #listing_block_from_time').attr('disabled', 'disabled');
      } else if (blockShowingsValue == 'AFTER') {
        $('span#block_scheduled_showings_after').show();
        $('#listing_block_from, #listing_block_from_time').removeAttr('disabled');

        $('span#block_scheduled_showings_before').hide();
        $('#listing_block_until, #listing_block_until_time').attr('disabled', 'disabled');
      } else if (blockShowingsValue == 'BETWEEN') {
        $('span#block_scheduled_showings_after, span#block_scheduled_showings_before').show();
        $('#listing_block_from, #listing_block_from_time, #listing_block_until, #listing_block_until_time').removeAttr('disabled');
      } else {
        $('span#block_scheduled_showings_after, span#block_scheduled_showings_before').hide();
        $('#listing_block_from, #listing_block_from_time, #listing_block_until, #listing_block_until_time').attr('disabled', 'disabled');
      }
    });
  }

  init(form) {
    this.bindEvents(form);

    flatpickr('#listing_block_until, #listing_block_from', {dateFormat: 'M j, Y', allowInput: true});

    $('#listing_application_link_policy').trigger('change');

    $('#listing_block_scheduled_showings').trigger('change');
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('#listing_options form');
  if (form) {
    new OptionsForm().init(form, false);
  }

  document.addEventListener('options-form:updated', (e) => {
    const form = document.querySelector('#listing_options form');
    new OptionsForm().init(form);
  });
});
