class CancelAccount {
  bindEvents() {
    document.getElementById('show_cancel_instruction').addEventListener('click', () => {
      document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
        content: document.getElementById('prevent_cancel').innerHTML,
        onComplete: () => {
          document.querySelector('.js-modal-wrap .js-change-billing-plan').addEventListener('click', () => {
            document.dispatchEvent(new CustomEvent('sm-modal:close'));
            document.getElementById('billing-plan-form-trigger').click();
          });

          document.querySelector('.js-modal-wrap .js-proceed-cancel').addEventListener('click', () => {
            document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
              content: document.getElementById('cancel_instruction').innerHTML
            }}));
          });
        }
      }}));
    });
  }

  init() {
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const cancelBtn = document.getElementById('show_cancel_instruction');
  if (cancelBtn) {
    new CancelAccount().init();
  }
});
