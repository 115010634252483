class EditListing {
  initSelfGuidedTour() {
    $('#edit-self-guided-tour').click(function(){
      document.querySelector('#self_guided_tour form').dispatchEvent(new CustomEvent('partial-form:enable'));
    });
  
    $(document).on('click', 'a.upload_audio', function() {
      var a = $(this);
      a.siblings().show();
      a.hide();
      return false;
    });
    
    $(document).on('click', 'a.upload_photo', function() {
      var a = $(this);
      a.siblings().show();
      a.hide();
      return false;
    });
  }

  bindEvents() {
    // used for craigslist text selection
    $(document).on('click', '.text_area', (e) => {
      $(e.target).select();
      return false;
    });
  }

  setHideInactive(value, submit) {
    const filter = document.querySelector('.js-listing-edit-filter');
    if (value) {
      document.querySelector('#lfedit_active').classList.add('current');
      document.querySelector('#lfedit_all').classList.remove('current');
      document.querySelector('#lfedit').value = filter.dataset.filterActive;
    } else {
      document.querySelector('#lfedit_active').classList.remove('current');
      document.querySelector('#lfedit_all').classList.add('current');
      document.querySelector('#lfedit').value = filter.dataset.filterAll;
    }
    document.querySelector('.mj-combobox').dispatchEvent(new CustomEvent('hide_inactive', {detail: {value: value}}));
    if (submit) {
      Rails.fire(document.querySelector('#lfe_settings'), 'submit');
    }
  }

  initEditFilter() {
    const filter = document.querySelector('.js-listing-edit-filter');
    if (filter) {
      if (!JSON.parse(filter.dataset.noListingsSelect)) {
        this.setHideInactive(filter.dataset.filterOnEditPage === filter.dataset.filterActive);
      }
    }

    document.querySelector('#lfedit_active').addEventListener('click', () => {
      this.setHideInactive(true, true);
      return false;
    });
    document.querySelector('#lfedit_all').addEventListener('click', () => {
      this.setHideInactive(false, true);
      return false;
    });
  }

  init() {
    this.initSelfGuidedTour();
    this.initEditFilter();
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const editListingWrap = document.querySelector('#edit-listing');
  if (editListingWrap) {
    new EditListing().init();
  }
});